<template>
    <div class="mainbox">
        <div class="detail_left">
            <div class="detail_left_detail">
                <div class="til">招生简章</div>
                <div class="detail">
                    <div v-if="dataItem.projectType == 0" class="elp"><span>招生层次：</span>{{ dataItem.level }} </div>
                    <div v-if="dataItem.projectType == 0" class="elp"><span>学习形式：</span> {{ dataItem.studyType }}</div>
                    <div v-if="dataItem.projectType == 0" class="elp"><span>学制：</span>{{ dataItem.academic }} </div>
                    <div v-if="dataItem.projectType == 0" class="elp"><span>招生对象：</span>{{ dataItem.enrollObject }}
                    </div>
                    <div v-if="dataItem.projectType == 0" class="elp"><span>招生计划：</span> {{ dataItem.enrollPlan }} 人
                    </div>
                    <div v-if="dataItem.projectType == 5" class="elp"><span>留学模式：</span>{{ dataItem.studyModeName }}
                    </div>
                    <div v-if="dataItem.projectType == 5" class="elp"><span>招生对象：</span>{{ dataItem.enrollObject }}
                    </div>
                    <div v-if="dataItem.projectType == 5" class="elp"><span>学费：</span>{{ dataItem.feeNumAll }}万/年 </div>
                    <div v-if="dataItem.projectType == 5" class="elp"><span>招生计划：</span>共计{{ dataItem.enrollPlan }}人
                    </div>
                    <div v-if="dataItem.projectType == 1" class="elp"><span>招生类别：</span>{{ dataItem.projectCategoryName
                        }} </div>
                    <div v-if="dataItem.projectType == 1" class="elp"><span>招生对象：</span> {{ dataItem.enrollObject }}
                    </div>
                    <div v-if="dataItem.projectType == 1" class="elp"><span>招生计划：</span>{{ dataItem.enrollPlan }} 人
                    </div>

                </div>
            </div>
            <div v-if="dataItem.projectType == 0" class="detail_left_cost">
                <div class="detail_left_cost_til">
                    <div class="line"></div> 费用介绍
                </div>
                <div style="margin-top: 30px;">
                    <el-table :data="dataItem.enrollBriefsFlatfeeList" style="width: 100%">
                        <el-table-column style="text-align: center;" center label="统一费用">
                            <el-table-column prop="level" label="招生层次">
                                <template slot-scope="{row}">
                                    {{ row.level == 0 ? '高起专' : row.level == 1 ? "高起本" : '专升本' }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="feeType" label="费用类型">
                            </el-table-column>
                            <el-table-column prop="feeNum" label="费用">
                                <template slot-scope="{row}">
                                    {{ row.feeNum }}元/{{ row.feeMode == 0 ? '年' : row.feeMode == 1 ? "月" : '人' }}
                                </template>
                            </el-table-column>

                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div v-if="dataItem.projectType == 1" class="detail_left_cost">
                <div class="detail_left_cost_til">
                    <div class="line"></div> 费用介绍
                </div>
                <div style="margin-top: 30px;">
                    <el-table :data="dataItem.enrollBriefsFlatfeeList" style="width: 100%">
                        <el-table-column prop="feeType" label="费用类型">
                        </el-table-column>
                        <el-table-column prop="feeNum" label="费用">
                            <template slot-scope="{row}">
                                {{ row.feeNum }}元/{{ row.feeMode == 0 ? '年' : row.feeMode == 1 ? "月" : '人' }}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div v-if="dataItem.projectType == 0" class="detail_left_cost">
                <el-table :data="stydyLists" style="width: 100%">
                    <el-table-column style="text-align: center;" center label="学费">
                        <el-table-column prop="majorName" label="专业名称">
                        </el-table-column>
                        <el-table-column prop="level" label="招生层次">
                            <template slot-scope="{row}">

                                <div v-if="row.level">
                                    <span v-for="(item, index) in row.level.split(',')" :key="index">
                                        {{ item == 0 ? '高起专' : item == 1 ? "高起本" : '专升本' }}
                                    </span>
                                </div>

                            </template>
                        </el-table-column>
                        <el-table-column prop="studyType" label="学习形式">
                        </el-table-column>
                        <el-table-column prop="feeNum" label="费用">
                            <template slot-scope="{row}">
                                <div v-if="row.enrollBriefsFlatfeeList">
                                    <span v-for="(item, index) in row.enrollBriefsFlatfeeList" :key="index">
                                        {{ item.feeType }}{{ item.feeNum }}元/{{ item.feeMode == 0 ? '年' : item.feeMode
                                            == 1 ? "月" : '人' }} <span
                                            v-if="index != row.enrollBriefsFlatfeeList.length - 1">+</span>
                                    </span>
                                </div>
                            </template>
                        </el-table-column>

                    </el-table-column>
                </el-table>
                <el-pagination background style="display: flex;justify-content: center;" :page-count="4"
                    class="pagination partner-pagination" :current-page="pageNum" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total"
                    @current-change="handleCurrentChange" @size-change="handleSizeChange"
                    :page-sizes="[10, 20, 50, 100]" />

            </div>
            <div class="detail_left_cost">
                <div class="detail_left_cost_til">
                    <div class="line"></div> 简章详情
                </div>
                <div style="margin-top: 30px;line-height: 1.5;" v-html="ImgSizeLimit(dataItem.detial)">
                </div>
            </div>
            <div v-if="dataItem.enrollBriefsContactList && dataItem.enrollBriefsContactList.length > 0" class="phone">
                <div class="til">
                    <img src="@/assets/img/TechnicalCollege/19.png" alt="">联系方式
                </div>
                <div class="phone_detail">
                    <div class="t_phone">
                        <div v-for="(item, index) in dataItem.enrollBriefsContactList" :key="index">
                            {{ item.name }}<span style="font-weight: bold;"> : {{ item.phone }}</span>
                        </div>
                    </div>
                    <div class="t_qrcode">
                        <div>
                            <el-image style="width: 80px;height: 80px;" class="img"
                                :src="dataItem.code ? dataItem.code : copyright.wechatMpName" fit="contain"></el-image>

                        </div>
                        <div style="margin-left: 36px;">
                            <p>微信扫一扫</p>
                            <p style="margin-top: 20px;"> 立即咨询</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="detail_right">
            <div v-if="newsList && newsList.length > 0" class="s_news">
                <div class="s_news_til">学校新闻动态</div>
                <div class="s_news_con">
                    <div @click="goNext(`/gxGathers/school/decsDetail?newid=${item.id}&id=${id}`)"
                        class="s_news_con_item " v-for="(item, index) in newsList" :key="index">
                        <div class="point"></div>
                        <div style="width: 200px;" class="elp"> {{ item.newTitle }}</div>
                    </div>
                </div>
            </div>

            <div v-if="qusetionList && qusetionList.length > 0" style="margin-top: 28px;" class="s_news">
                <div class="s_news_til">学校常见问题</div>
                <div class="s_news_con">
                    <div class="s_news_con_item " v-for="(item, index) in qusetionList" :key="index">
                        <div class="point"></div>
                        <div style="width: 200px;" class="elp"> {{ item.questionName }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
    components: {},

    data() {
        return {
            id: '',
            projectType: '',
            pageSize: 10,
            pageNum: 1,
            total: 0,
            dataItem: {},
            stydyLists: [],
            newsList: [],
            qusetionList: [],
            copyright: {},
            search: {
                pageSize: 8,
                pageNum: 1,
                projectTypeId: '',
                projectCategoryId: '',
                schoolId: ''
            },
        };
    },
    created() {
        this.copyright = getDomain();
        this.search.projectTypeId = localStorage.getItem('projectTypeId');

        this.id = this.$route.query.enrollId;
        this.search.schoolId = this.$route.query.id;
        this.projectType = localStorage.getItem('projectType');
        this.selectSchoolBriefsData()
        this.getContentNewPcList()
        this.getSchoolProblemPcList()
    },
    mounted() {

    },
    methods: {
        selectSchoolBriefsData() {
            if (this.projectType == 0) {
                know.selectSchoolBriefsData({
                    id: this.id,
                }).then((res) => {
                    this.dataItem = res.data
                    this.selectTuitionFlatfeePage()
                });
            } else if (this.projectType == 5) {
                know.selectBriefsPcAbroadData({
                    id: this.id,
                }).then((res) => {
                    this.dataItem = res.data
                    this.selectTuitionFlatfeePage()
                });
            } else if (this.projectType == 1) {
                know.selectBriefsData({
                    id: this.id,
                }).then((res) => {
                    this.dataItem = res.data
                    this.selectTuitionFlatfeePage()
                });
            }


        },
        selectTuitionFlatfeePage() {
            know.selectTuitionFlatfeePage({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                id: this.id,
            }).then((res) => {
                this.stydyLists = res.rows
                this.total = res.total
            });
        },
        getContentNewPcList() {
            know.getContentNewPcList({ ...this.search }).then((res) => {
                this.newsList = res.rows
            });
        },
        getSchoolProblemPcList() {
            know.getSchoolProblemPcList({ ...this.search }).then((res) => {
                this.qusetionList = res.rows
            });
        },

        handleSizeChange(val) {
            this.pageNum = 1
            this.pageSize = val
            this.selectTuitionFlatfeePage()
        },
        handleCurrentChange(val) {
            this.pageNum = val
            this.selectTuitionFlatfeePage()
        },

    },
};
</script>

<style lang="less" scoped>
.mainbox {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;

    .detail_left {
        width: 938px;
        border-radius: 6px 6px 6px 6px;

        .detail_left_detail {
            padding: 30px;
            background: #fff;

            .til {
                text-align: center;
                font-family: PingFangSC-Semibold, PingFangSC-Semibold;
                font-weight: normal;
                font-size: 28px;
                color: #333333;
            }

            .detail {
                width: 892px;
                min-height: 115px;
                background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #FFFBF7 100%);
                border-radius: 6px 6px 6px 6px;
                background: url('~@/assets/img/TechnicalCollege/17.png');
                background-size: 100% 100%;
                margin: 0 auto;
                margin-top: 20px;
                display: flex;
                padding: 25px;
                flex-wrap: wrap;



                >div {
                    flex-shrink: 0;

                    width: 48%;
                    font-family: PingFangSC-Regular, PingFangSC-Regular;
                    font-weight: normal;
                    font-size: 14px;
                    color: #555555;
                    margin-bottom: 5px;

                    span {
                        font-size: 16px;
                        color: #333333;
                    }
                }

            }
        }

        .detail_left_cost {
            width: 938px;
            padding: 20px;
            background: #FFFFFF;
            border-radius: 6px 6px 6px 6px;
            margin-top: 24px;

            .detail_left_cost_til {
                font-family: PingFangSC-Semibold, PingFangSC-Semibold;
                font-weight: normal;
                font-size: 18px;
                color: #333333;
                display: flex;
                align-items: center;

                .line {
                    width: 6px;
                    height: 18px;
                    background: #1061FF;
                    border-radius: 2px 2px 2px 2px;
                    margin-right: 10px;
                }
            }
        }

        .phone {
            margin-top: 20px;
            width: 938px;
            min-height: 200px;
            background: #FBFDFF;
            border-radius: 6px 6px 6px 6px;
            background: url('~@/assets/img/TechnicalCollege/18.png');
            background-size: 100% 100%;
            padding: 25px;

            .til {
                display: flex;
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 18px;
                color: #333333;

                img {
                    width: 21px;
                    margin-right: 6px;

                }
            }

            .phone_detail {
                display: flex;
                width: 600px;
                justify-content: space-between;
                align-items: center;
                margin-top: 23px;

                .t_phone {
                    font-family: PingFang SC, PingFang SC;
                    font-size: 16px;
                    color: #444444;
                    margin-left: 30px;

                    >div {
                        margin-bottom: 23px;

                    }
                }

                .t_qrcode {
                    display: flex;
                    align-items: center;

                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 12px;
                    color: #444444;
                }
            }
        }






    }

    .detail_right {
        width: 236px;
        height: fit-content;
        // background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;

        .s_news {
            width: 236px;
            background: #FFFFFF;
            border-radius: 6px 6px 6px 6px;


            .s_news_til {
                width: 236px;
                height: 46px;
                line-height: 46px;
                text-align: center;
                background: #FFFFFF;
                border-radius: 6px 6px 0px 0px;
                font-family: PingFangSC-Semibold, PingFangSC-Semibold;
                font-weight: normal;
                font-size: 18px;
                color: #FFFFFF;
                background: url('~@/assets/img/TechnicalCollege/20.png');
                background-size: 100% 100%;
            }

            .s_news_con {
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: normal;
                font-size: 14px;
                color: #444444;
                padding: 0 12px;

                .s_news_con_item {
                    display: flex;
                    align-items: center;

                    height: 40px;
                    cursor: pointer;

                    .point {
                        width: 3px;
                        height: 3px;
                        background: #E1E1E1;
                        margin-right: 9px;
                    }

                    &:hover {
                        color: #BD1212;
                    }
                }
            }
        }
    }


}

img {
    display: block;
    width: 100%;
    height: 100%;
}

::v-deep .cell {
    text-align: center !important;
}
</style>